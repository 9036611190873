import React, { useState, useEffect, useRef } from "react";
import "./quality.css";
import paragraphImage1 from "../Assets/Quality/AutomationTesting.jpg";
import paragraphImage2 from "../Assets/Quality/ManualTesting.jpg";
import paragraphImage3 from "../Assets/Quality/APITesting.jpg";
import paragraphImage4 from "../Assets/Quality/MobileQA.jpg";
import paragraphImage5 from "../Assets/Quality/SecurityTesting.jpg";
import paragraphImage6 from "../Assets/Quality/PerformanceTesting.jpg";
import paragraphImage7 from "../Assets/Quality/QAAnalysis.jpg";
import paragraphImage8 from "../Assets/Quality/IoTTesting.jpg";

const points = [
  {
    title: "Automation Testing Services",
    description:
      "Automation Testing involves using specialized software tools to execute pre-scripted tests on a software application before it is released into production. The goal is to automate repetitive but necessary testing tasks to ensure that the application is stable and performs as expected. Benefits include faster test execution, higher test coverage, and the ability to run tests 24/7 without human intervention.",
    image: paragraphImage1,
  },
  {
    title: "Manual Testing Services",
    description:
      "Manual Testing is the process of manually executing test cases without the use of any automation tools. It involves a tester playing the role of an end-user and using most of the application's features to ensure correct behavior. Manual testing helps in identifying user interface issues, usability problems, and other anomalies that automated tests might miss.",
    image: paragraphImage2,
  },
  {
    title: "API Testing Services",
    description:
      "API Testing involves testing the Application Programming Interfaces (APIs) directly and as part of integration testing to determine if they meet expectations for functionality, reliability, performance, and security. It is crucial for ensuring that different software components can communicate and work together seamlessly. API testing typically includes validation of endpoints, error codes, and response times.",
    image: paragraphImage3,
  },
  {
    title: "Mobile QA",
    description:
      "Mobile Quality Assurance (QA) focuses on ensuring that mobile applications function correctly across a wide range of devices and operating systems. It includes functional testing, usability testing, performance testing, and security testing of mobile applications. The goal is to provide a seamless and bug-free experience for end-users on their mobile devices.",
    image: paragraphImage4,
  },
  {
    title: "Security Testing Services",
    description:
      "Security Testing aims to identify vulnerabilities, threats, and risks in a software application to prevent malicious attacks. It involves assessing the security of the application by finding security weaknesses and vulnerabilities. This can include penetration testing, vulnerability scanning, security auditing, and code reviews to ensure that sensitive data is protected from unauthorized access and breaches.",
    image: paragraphImage5,
  },
  {
    title: "Performance Testing Services",
    description:
      "Performance Testing involves evaluating the speed, responsiveness, and stability of a software application under a particular workload. It is crucial for identifying performance bottlenecks and ensuring that the application can handle expected user load. Types of performance testing include load testing, stress testing, scalability testing, and endurance testing.",
    image: paragraphImage6,
  },
  {
    title: "QA Analysis",
    description:
      "Quality Engineering at Xploria is dedicated to ensuring the highest standards of software quality and reliability. Our comprehensive QA services encompass both web and mobile application validation, leveraging advanced testing methodologies to detect and address potential issues early in the development cycle. We specialize in Automation Testing, Manual Testing, API Testing, and Mobile QA, providing thorough coverage across all aspects of your software. Our Security Testing services safeguard your applications against vulnerabilities, while Performance Testing ensures optimal functionality under varying loads. With a strong focus on IoT Testing, we guarantee seamless integration and performance of connected devices. At Xploria, our QA Analysis delivers actionable insights, driving continuous improvement and delivering exceptional user experiences.",
    image: paragraphImage7,
  },
  {
    title: "IoT Testing Services",
    description:
      "Internet of Things (IoT) Testing ensures that IoT devices and applications function correctly and securely. It involves testing the interaction between connected devices, data exchange, and the performance of IoT systems under various conditions. IoT testing includes functional testing, security testing, performance testing, and compatibility testing to ensure the reliability and safety of IoT solutions.",
    image: paragraphImage8,
  },
];

const Qu_Banner = ({handleContactClick}) => {
  const Banner_data={
      heading:"Quality Engineering",
  }
return (
  <div className="qu_banner_container">
      <div className="qu_bannerContent">
          <h2 className="qu_banner_heading">{Banner_data.heading}</h2>
          <button className="qu-banner-button" onClick={handleContactClick}>Contact Us</button>
      </div>
  </div>
)
}
const Quality = ({handleContactClick}) => {
  const [activeIndex, setActiveIndex] = useState(0); // Initialize with 0 for default expansion
  const contentWrapperRef = useRef(null);
  const headingRef = useRef(null);
  const paragraphTextRef = useRef(null);
  const paragraphImageRef = useRef(null);

  const handleExpand = (index) => {
    // Prevent deactivation of the currently active index
    if (activeIndex !== index) {
      setActiveIndex(index);
    }
  };

  useEffect(() => {
    const elements = [
      headingRef.current,
      paragraphTextRef.current,
      paragraphImageRef.current,
      contentWrapperRef.current,
    ];

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("reveal");
            observer.unobserve(entry.target);
          }
        });
      },
      { threshold: 0.1 }
    );

    elements.forEach((element) => {
      if (element) observer.observe(element);
    });

    return () => {
      elements.forEach((element) => {
        if (element) observer.unobserve(element);
      });
    };
  }, []);

  const currentPoint = points[activeIndex] || {
    image: "",
    title: "Default Title",
  }; // Safeguard

  return (
    <div className="quality-banner-container">
      <Qu_Banner handleContactClick={handleContactClick}/>
      <div className="quality-paragraph">
        <h2 ref={headingRef} className="quality-paragraph-heading">
          Quality Engineering
        </h2>
        <p ref={paragraphTextRef} className="quality-paragraph-text">
          Quality Engineering at Xploria ensures that your organization's
          software applications meet the highest standards of quality and
          reliability. Our comprehensive QA services encompass both web and
          mobile validation, providing a robust framework for delivering
          flawless user experiences. Xploria's expertise includes automation
          testing, manual testing, and API testing services, ensuring thorough
          coverage and efficiency. Our mobile QA team specializes in validating
          applications across various devices and platforms. Security and
          performance testing services are designed to safeguard your
          applications against vulnerabilities and ensure optimal performance
          under load. With our in-depth QA analysis, we identify and address
          potential issues early in the development cycle. Xploria's IoT testing
          services ensure that your connected devices perform seamlessly in the
          real world. Our dedicated team of experts is committed to delivering
          high-quality software that drives your business success.
        </p>
      </div>
      <div className="quality-content">
        <h3 className="quality-components-heading">
          Quality Engineering Process
        </h3>
        <div ref={contentWrapperRef} className="quality-content-wrapper">
          <div className="quality-image-container">
            <img
              key={activeIndex}
              src={currentPoint.image} // Safeguard
              alt={currentPoint.title}
              className="quality-paragraph-image"
              ref={paragraphImageRef}
            />
          </div>
          <div className="quality-text-content">
            {points.map((point, index) => (
              <div key={index} className="quality-point">
                <button
                  onClick={() => handleExpand(index)}
                  className={`quality-point-title ${
                    activeIndex === index ? "quality-active" : ""
                  }`}
                  disabled={activeIndex === index} // Disable if it's active
                >
                  <span className="quality-point-icon"></span>
                  {point.title}
                </button>
                {activeIndex === index && (
                  <div className="quality-point-details">
                    <p className="quality-point-description">
                      {point.description}
                    </p>
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Quality;

import "../css/ProposalPage.css"
import AOS from 'aos';
import 'aos/dist/aos.css';
AOS.init();


export const ProposalPage = () => {
  return (
    <div style={{backgroundColor:"#F6F6F6"}}>
        <Pro_Banner/>
        <Pro_form/>
    </div>
  )
}



export const Pro_Banner = () => {
    const Banner_data={
      heading:"Request for proposal for services",
      description:"Collaboration is the cornerstone of success. Submit your proposal and let's achieve greatness together.",
  }
  return (
  <div className="pro_banner_container">
      <div className="pro_bannerContent">
          <h2 className="pro_banner_heading" data-aos="fade-down" data-aos-duration="1000" >{Banner_data.heading}</h2>
          <p className="pro_banner_desc" data-aos="fade-down" data-aos-duration="1000" >{Banner_data.description}</p>
      </div>
  </div>
  )
  }


export const Pro_form = () => {
    
  return (
    <div className="Pro_form_container">
        <p className="pro_form_para" data-aos="fade-up" data-aos-duration="1000">Thank you for your interest in Xploria's firm services. Please take a few moments to complete this form. Documents can be uploaded if needed to clarify your request. This mailbox only accepts qualified proposal requests for Xploria's services.</p>
    <form action="" className="Pro_form" data-aos="fade-up" data-aos-duration="1000">
        <div className="form_name_section">
            <select name="cars" id="title_select">
                <option selected>Title</option>
                <option>Dr.</option>
                <option>Miss.</option>
                <option>Mr.</option>
                <option>Mrs.</option>
                <option>Ms.</option>
                <option>Prof.</option>
            </select>

            <div className="form_input_grp">
                <input className="name_grp" type="text" placeholder=" "/>
                <label htmlFor="">Name</label>
            </div>
        </div>

        <div className="form_input_grp">
            <input className="position_grp" type="text" placeholder=" "/>
            <label htmlFor="">Position / Job Title</label>
        </div>

        <div className="form_input_grp">
            <input className="email_grp" type="text" placeholder=" "/>
            <label htmlFor="">Email Address</label>
        </div>

        <div className="form_input_grp">
            <input className="number_grp" type="text" placeholder=" "/>
            <label htmlFor="">Phone Number</label>
        </div>

        <div className="form_input_grp">
            <input className="organization_grp" type="text" placeholder=" "/>
            <label htmlFor="">Company / Organization Name</label>
        </div>

        <div className="form_input_grp">
            <select id="industry_select">
                <option selected>Industry</option>
                <option>Energy, Resources & Industrials</option>
                <option>Financial Services</option>
                <option>Government & Public Services</option>
                <option>Life Sciences & Health Care</option>
                <option>Technology, Media & Telecommunications</option>
            </select>
        </div>

        <div className="form_input_grp">
            <select id="revenue_select">
                <option selected>Yearly Revenue</option>
                <option>US $1 million - $5 million</option>
                <option>US $5 million - $10 million</option>
                <option>US $10 million - $99 million</option>
                <option>US $100 million - $500 million</option>
                <option>US $500 million - $999 million</option>
                <option>US $1 billion - $5 billion</option>
                <option>Greater than $5 billion</option>
            </select>
        </div>

        <label htmlFor="images" className="drop-container" id="dropcontainer">
            <span className="drop-title">Drop files here</span>or
            <input type="file" id="images" accept="image/*" required />
        </label>

        <div className="form_input_grp">
            <textarea cols="30" rows="6" placeholder=" "></textarea>
            <label id="txtarea_lbl" htmlFor="">Comments and Instructions</label>
        </div>
        <button className="submit_btn">Submit</button>
    </form>
</div>
  )
}

import React, { useState, useEffect, useRef } from "react";
import "./technology.css";
import paragraphImage1 from "../Assets/Technology/Core_Business_Operations.jpg";
import paragraphImage2 from "../Assets/Technology/StrategyAnalytics.jpg";
import paragraphImage3 from "../Assets/Technology/DigitalTransformation.jpg";
import paragraphImage4 from "../Assets/Technology/EnterpriseTechnology.jpg";

const points = [
  {
    title: "Core Business Operations",
    description:
      "Focuses on optimizing fundamental activities and workflows within an organization. This includes streamlining processes, enhancing efficiency, and ensuring that day-to-day operations are running smoothly to support overall business goals.",
    image: paragraphImage1,
  },
  {
    title: "Strategy & Analytics",
    description:
      "Involves developing and implementing strategic plans based on data-driven insights. This encompasses analyzing market trends, assessing business performance, and using analytics to inform decision-making and drive growth.",
    image: paragraphImage2,
  },
  {
    title: "Digital Transformation",
    description:
      "Refers to the integration of digital technologies into all areas of a business. This transformation aims to improve operational efficiency, enhance customer experiences, and foster innovation by adopting modern tools and methodologies.",
    image: paragraphImage3,
  },
  {
    title: "Enterprise Technology & Performance",
    description:
      "Focuses on leveraging advanced technologies to improve business performance. This includes implementing robust IT systems, optimizing infrastructure, and ensuring that technology investments align with organizational objectives to drive long-term success.",
    image: paragraphImage4,
  },
];
const Tech_Banner = ({handleContactClick}) => {
  const Banner_data={
      heading:"Technology Services",
  }
return (
  <div className="tech_banner_container">
      <div className="tech_bannerContent">
          <h2 className="tech_banner_heading">{Banner_data.heading}</h2>
          <button className="tech-banner-button" onClick={handleContactClick}>Contact Us</button>
      </div>
  </div>
)
}

const Technology = ({handleContactClick}) => {
  const [activeIndex, setActiveIndex] = useState(0); // Initialize with 0 for default expansion
  const contentWrapperRef = useRef(null);
  const headingRef = useRef(null);
  const paragraphTextRef = useRef(null);
  const paragraphImageRef = useRef(null);

  const handleExpand = (index) => {
    // Prevent deactivation of the currently active index
    if (activeIndex !== index) {
      setActiveIndex(index);
    }
  };

  useEffect(() => {
    const elements = [
      headingRef.current,
      paragraphTextRef.current,
      paragraphImageRef.current,
      contentWrapperRef.current,
    ];

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("reveal");
            observer.unobserve(entry.target);
          }
        });
      },
      { threshold: 0.1 }
    );

    elements.forEach((element) => {
      if (element) observer.observe(element);
    });

    return () => {
      elements.forEach((element) => {
        if (element) observer.unobserve(element);
      });
    };
  }, []);

  const currentPoint = points[activeIndex] || {
    image: "",
    title: "Default Title",
  }; // Safeguard

  return (
    <div className="technology-banner-container">
    <Tech_Banner handleContactClick={handleContactClick}/>
      <div className="technology-paragraph">
        <h2 ref={headingRef} className="technology-paragraph-heading">
          Technology Services
        </h2>
        <p ref={paragraphTextRef} className="technology-paragraph-text">
          Our Technology Services are designed to enable seamless digital
          integration throughout your business operations. Our comprehensive
          approach includes implementations, integrations, and application
          ecosystem optimization, ensuring that every phase of your business
          journey is interconnected and efficient. We offer expertise in
          Consulting & Value Road Map to guide strategic planning, Solution
          Implementation to deploy robust systems, Application Management for
          ongoing support, Upgrade & Migration for evolving technology needs,
          Infrastructure Services to maintain a solid technological foundation,
          and Testing Services to ensure system reliability. Our solutions bring
          significant benefits, including improved collaboration, reduced
          time-to-market, and enhanced operational efficiency, while also
          supporting sustainable practices.
        </p>
      </div>
      <div className="technology-content">
        <h3 className="technology-components-heading">Innovative Solutions</h3>
        <div ref={contentWrapperRef} className="technology-content-wrapper">
          <div className="technology-image-container">
            <img
              key={activeIndex}
              src={currentPoint.image} // Safeguard
              alt={currentPoint.title}
              className="technology-paragraph-image"
              ref={paragraphImageRef}
            />
          </div>
          <div className="technology-text-content">
            {points.map((point, index) => (
              <div key={index} className="technology-point">
                <button
                  onClick={() => handleExpand(index)}
                  className={`technology-point-title ${
                    activeIndex === index ? "technology-active" : ""
                  }`}
                  disabled={activeIndex === index} // Disable if it's active
                >
                  <span className="technology-point-icon"></span>
                  {point.title}
                </button>
                {activeIndex === index && (
                  <div className="technology-point-details">
                    <p className="technology-point-description">
                      {point.description}
                    </p>
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Technology;

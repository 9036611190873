import "../css/AboutusPage.css"
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { useState,useEffect} from "react";


export const AboutusPage = ({handleContactClick}) => {
  return (
    <div style={{backgroundColor:"#F6F6F6"}}>
      <AboutusBanner/>
      <AboutusContent/>
      <Vision_Mission/>
      <AboutusLeaders/>
      <AboutusPhotoGallery/>
      <Achievement/>
      <AboutusContact handleContactClick={handleContactClick}/>
    </div>
  )
}



export const AboutusBanner = () => {
  const Banner_data={
    heading:"About XPLORIA",
    description:"XPLORIA is a leading innovator in technology solutions, dedicated to transforming businesses through cutting-edge software and consulting services.",
}
return (
<div className="about_banner_container">
    <div className="about_bannerContent">
        <h2 className="about_banner_heading" data-aos="fade-down" data-aos-duration="1000" >{Banner_data.heading}</h2>
        <p className="about_banner_desc" data-aos="fade-down" data-aos-duration="1000" >{Banner_data.description}</p>
    </div>
</div>
)
}


 const AboutusContent = () => {
  return (
    <div className="AboutusContent_container"  data-aos="fade-up" data-aos-duration="1000">
        <ul className="about__content">
          <li><span>Since 2009</span>, XPLORIA has been delivering top-notch industry-specific implementation practices to clients across North America, Europe, the Middle East, and Asia</li>
          <li>We specialize in helping OEMs, Tier-1/ Tier-2 Suppliers, and mid-size organizations achieve their business goals within set timelines and budgets.</li>
          <li>XPLORIA’s core strengths lie in our <span>global PLM expertise</span>, advanced toolset, and deep knowledge of multiple PLM platforms like <span>3DEXPERIENCE and ARAS Innovator</span>.</li>
          <li>Our clients appreciate our best-in-class project management, responsive service, results-driven approach, and commitment to quality, making us their preferred PLM system integrator</li>
          <li><span>At Xploria, We GET THINGS DONE!</span></li>
          
        </ul>
    </div>
  )
}

 const Vision_Mission = () => {
  return (
    <div className="vm_container">
        <div className="vision_container">
              <div className="vision_content"data-aos="fade-up" data-aos-duration="1000">
                      <h2>Our Vision</h2>
                      <p>We have a vision to enable digital transformation in product manufacturing enterprises using PLM to efficiently use Global resources, enable innovations and build scalable systems.</p>
              </div>
              <div className="vision_img_box"data-aos="fade-up" data-aos-duration="1000">
                    <img  className="vision_img" src="assets/vision.jpg" alt="" />
              </div>
        </div>
        <div className="mission_container">
              <div className="vision_img_box"data-aos="fade-up" data-aos-duration="1000">
                    <img  className="vision_img" src="assets/mission.png" alt="" />
              </div>
              <div className="vision_content"data-aos="fade-up" data-aos-duration="1000">
                      <h2>Our Mission</h2>
                      <p>Share our customer’s vision and help them to achieve it through digital transformation using PLM technology with our deep domain expertise and Global experience.</p>
               </div>
        </div>
    </div>
  )
}

const AboutusLeaders = () => {
  const leadersDatas=[
    {image:"assets/client1.jpg",name:"Sasi Kinjaram",position:"Project Manager",desc:"John Doe brings over 15 years of experience in the field of project management, specializing in PLM systems and technology integration. Throughout his career, John has successfully led numerous projects across various industries, including automotive, aerospace, and manufacturing",linkedin:"url"},
    {image:"assets/leader2.jpg",name:"Sasi Kinjaram",position:"Project Manager",desc:"John Doe brings over 15 years of experience in the field of project management, specializing in PLM systems and technology integration. Throughout his career, John has successfully led numerous projects across various industries, including automotive, aerospace, and manufacturing",linkedin:"url"},
    {image:"assets/leader3.jpg",name:"Sasi Kinjaram",position:"Project Manager",desc:"John Doe brings over 15 years of experience in the field of project management, specializing in PLM systems and technology integration. Throughout his career, John has successfully led numerous projects across various industries, including automotive, aerospace, and manufacturing",linkedin:"url"}
  ]
  return (
    <div className="our_leaders_container"  >
        <h2 className="leaders_heading"data-aos="fade-up" data-aos-duration="1000">Our Leaders</h2 >
        <div className="leaders_boxs" data-aos="fade-up" data-aos-duration="1000">

          {leadersDatas.map((data)=>(
                <div className="leader_card">
                <div className="leader_img_box">
                    <img className="leader_img" src={data.image} alt="" />
                </div>
                <div className="leader_name_box">
                        <p className="leader_name">{data.name}</p>
                        <p className="leader_position">{data.position}</p>
                </div>
                <div className="leader_info">
                      <p className="leader_desc">{data.desc}</p>
                </div>
                <div className="leader_contact">
                      <a className="leader_linkedin" href="">Linkedin</a>
                      <img  className="leader_linedin_img" src="assets/linkedin1.png" alt="" />
                </div>
        </div>
          ))}
              
              
        </div>
    </div>
  )
}



const AboutusPhotoGallery = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const totalSlides = 5; 

  const handleSlideChange = (index) => {
    setCurrentSlide(index);
  };
  const progressPercentage = ((currentSlide + 1) / totalSlides) * 100;

  // Cursor
  useEffect(() => {
    const cursor = document.querySelector('.cursor_container');
    const box = document.querySelector('.photos_box');

    const handleMouseMove = (e) => {
      cursor.setAttribute("style", "top:" + (e.pageY - 25) + "px; left:" + (e.pageX - 55) + "px;");
    };

    if (box) {
      box.addEventListener('mousemove', handleMouseMove);
    }

    return () => {
      if (box) {
        box.removeEventListener('mousemove', handleMouseMove);
      }
    };
  }, []);

 
  return (
    <div className="photo_gal_container">
      <h2 className="photo_gal_title"  data-aos="fade-up" data-aos-duration="1000">An energetic and innovative work culture is our secret to success</h2>
      <div className="cursor_container">
            <i class="ri-arrow-left-s-line"></i>
            <div className="cursor"></div>
            <i class="ri-arrow-right-s-line"></i>
      </div>
      <div className="photos_box"  data-aos="fade-up" data-aos-duration="1000">
        <Carousel
          showThumbs={false}
          showStatus={false}
          showArrows={false}
          swipeable={true}
          emulateTouch={true}
          centerMode={true}
          centerSlidePercentage={45}
          showIndicators={false}
          transitionTime={2000}
          onChange={handleSlideChange}
        >
           <div className="carousel_image">
            <img src="assets/group1_1.jpg" alt="Hand" />
          </div>
          
          <div className="carousel_image">
            <img src="assets/group2.png" alt="Client 1" />
          </div>
          <div className="carousel_image">
            <img src="assets/group3.png" alt="Client 2" />
          </div>
          <div className="carousel_image">
            <img src="assets/group1_2.jpg" alt="Hand" />
          </div>
          <div className="carousel_image">
            <img src="assets/group4.png" alt="Client 3" />
          </div>
        </Carousel>
      </div>
      <div className="progress_bar">
        <div 
          className="progress_bar_inside" 
          style={{ width: `${progressPercentage}%` }}
        ></div>
      </div>
    </div>
  )
}



const Achievement = () => {
  return (
    <div className="achievement_container">
        <div className="achievement_left" data-aos="fade-up" data-aos-duration="1000">
            <h2>Awards & Recognition</h2>
            <p>Recognized as the Most Recommended PLM Solutions Provider In 2020</p>
        </div>
        <div className="achievement_right"data-aos="fade-up" data-aos-duration="1000">
            <img src="assets/certificate.svg" alt="" />
        </div>
    </div>
  )
}

export const AboutusContact = ({handleContactClick}) => {
  return (
    <div className="aboutus_contact_container" data-aos="fade-up" data-aos-duration="1000" >
      <div className="contact_us_para">
          <h2 >For any inquiries, feel free to reach out and connect with us</h2>
      </div>
        
        <button className="contact_us__btn" onClick={handleContactClick}>Contact Us</button>
    </div>
  )
}

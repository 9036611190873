import React, { useEffect,useLayoutEffect,useRef, useState } from 'react'
import "../css/HomePage.css"
import gsap from 'gsap'
import AOS from 'aos';
import 'aos/dist/aos.css';
AOS.init();



export const LandingPage = ({ setShowCareers, setShowContact, setShowProposal }) => {


  const handleProposalPage = () => {
    setShowProposal(true);
    setShowCareers(false);
    setShowContact(false);
  };

  return (
    <div className="home">
      <video className="bg-video" src="/assets/video1.mp4" autoPlay loop muted></video>
      <div className="contentHome">
        <div className="highlight-text">
          <span className="highlight-word innovation">Innovation.</span>
          <span className="highlight-word expertise"> Expertise.</span>
          <span className="highlight-word delivered">Delivered.</span>
        </div>
        <a onClick={handleProposalPage}>
          <span className="explore-more" >Explore more <i className="ri-arrow-right-circle-line explore_more_icon"></i></span>
        </a>
      </div>
    </div>
  );
};







export const IconsSlide = () => {
  return (
    <div className='icons-container'>
      <div className='icon-slider'  data-aos="fade-up" data-aos-duration="1000">
        <img src="/assets/tesla.png" alt="Tesla" />
        <img src="/assets/google.png" alt="Google" />
        <img src="/assets/waymo.png" alt="Waymo" />
        <img src="/assets/zoox.png" alt="Zoox" />
         <img src="/assets/dresser.png" alt="dresser" />
        <img src="/assets/aurora.png" alt="Aurora" />
        <img src="/assets/pepper.png" alt="Pepper" />
        
      </div>

      <div className='icon-slider'  data-aos="fade-up" data-aos-duration="1000">
        <img src="/assets/tesla.png" alt="Tesla" />
        <img src="/assets/google.png" alt="Google" />
        <img src="/assets/waymo.png" alt="Waymo" />
        <img src="/assets/zoox.png" alt="Zoox" />
         <img src="/assets/dresser.png" alt="dresser" />
        <img src="/assets/aurora.png" alt="Aurora"/>
        <img src="/assets/pepper.png" alt="Pepper" />
       
      </div>
    </div>
  );
};
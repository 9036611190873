import { useState } from "react";
import "../css/JobDetails.css"

export const JobDetails = ({selectedJobIndex,handleBackbtn,handleApplyNow,job_Datas}) => {

  return (
    <div style={{backgroundColor: "#F6F6F6"}}>
      <Job_DetailsBanner job_Datas={job_Datas} selectedJobIndex={selectedJobIndex} />
      <Job_Contents job_Datas={job_Datas} selectedJobIndex={selectedJobIndex} handleBackbtn={handleBackbtn} handleApplyNow={handleApplyNow}/>
    </div>
  )
}

const Job_DetailsBanner = ({job_Datas,selectedJobIndex}) => {
  const {job_name,banner_img,banner_desc} =job_Datas[selectedJobIndex];

  return (
    <div className="job_detail_banner_container">
      <img className="job_img" src={banner_img} alt="" />
        <div className="job_detail_bannerContent"> 
            <h2 className="job_detail_banner_heading" data-aos="fade-down" data-aos-duration="1000">{job_name}</h2>
            <p className="job_details_banner_desc" data-aos="fade-down" data-aos-duration="1000" >{banner_desc}</p>
        </div>
    </div>
  )
}


const Job_Contents = ({job_Datas,selectedJobIndex,handleBackbtn,handleApplyNow}) => {

   const {job_name,job_img,job_location,job_date,about_job_role,skills,job_desc,skill_required} =job_Datas[selectedJobIndex];
  return (
    <>
    <button className="back_btn" onClick={handleBackbtn}><i class="ri-arrow-left-double-line"></i></button>
    <div className="whole_job_container">
     
        <div className="job_left">
            {/* About The Role */}
            <div className="about_role_box">
                  <p className="about_sub_head">About this role</p>
                    <div className="about_role_container">
                        <div className="role_img_box">
                              <img className="role_img"src={job_img} alt={job_name} />
                        </div>
                        <div className="about_role_content">
                            <h3 className="job__name">{job_name}</h3>
                            <p className="job__loc"><i className="ri-map-pin-fill"></i>{job_location}</p>
                            <p className="job__date"><i className="ri-calendar-2-line"></i>Posted On:<span>{job_date}</span></p>
                        </div>
                    </div>
                    <p className="about_the_role_para">{about_job_role}</p>
            </div>

          {/*  Skills */}

          <div className="skills_container">
            <p className="about_sub_head">Skills</p>
                <ul className="skill_box">
                 {skills.map((skill)=>(
                  <li>{skill}</li>
                 ))}
                </ul>
          </div>

          {/* Description */}

          <div className="job_desc_container">
            <p className="about_sub_head">Job Description:</p>
            <ul className="job_description_lists">
                {job_desc.map((desc, index) => (
                  <li key={index}>
                    <i className="ri-arrow-right-double-line"></i> {desc}
                  </li>
                ))}
            </ul>
          </div>

          <div className="job_desc_container">
            <p className="about_sub_head">Skills Required  :</p>
            <ul className="job_description_lists">
                {skill_required.map((skill, index) => (
                  <li key={index}>
                    <i className="ri-arrow-right-double-line"></i> {skill}
                  </li>
                ))}
            </ul>
          </div>
        </div>

        <div className="job_right">

          <div className="job_benefits">
          <p className="about_sub_head">Benefits:</p>
          <p>This job offers the following benefits</p>
            <div className="job_benefit_boxes">
                <div className="job_benefit_box">
                      <i class="ri-hospital-line"></i>
                      <p>Health Insurance</p>
                </div>
                <div className="job_benefit_box">
                      <i class="ri-time-line"></i>
                      <p>Flexible Hours</p>

                </div>
                <div className="job_benefit_box">
                      <i class="ri-bank-line"></i>
                      <p>Pension Scheme</p>
                </div>
                <div className="job_benefit_box">
                      <i class="fa-solid fa-chart-column"></i>
                      <p>Share Scheme</p>

                </div>
                <div className="job_benefit_box">
                      <i class="fa-solid fa-dumbbell"></i>
                      <p>Gym membership</p>
                </div>
                <div className="job_benefit_box">
                      <i class="ri-taxi-line"></i>
                      <p>Company car</p>
                </div>
            </div>
          </div>

            <div className="job_loc_container">
                  <p className="about_sub_head">Location</p>
                  <p>#104, T1, Third Floor,13th Main Road, Jakkasandra,Sector 7, HSR Layout 5th Sector,Bengaluru – 560034</p>
                  <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3888.8288205597723!2d77.63524410904868!3d12.91872121598604!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae150173a9df0f%3A0xd30009b0e83ae1ea!2sXPLORIA!5e0!3m2!1sen!2sin!4v1722343531734!5m2!1sen!2sin" width="100%" height="250" style={{border:0}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                  <p className="view_on_map">View on Google Maps</p>
            </div>

            <div className="share_container">
                      <div className="share_sub_section">
                              <a href="#"><img src="assets/facebook.png" alt="Facebook"/></a>
                              <p>Share on Facebook</p>
                      </div>
                      <div className="share_sub_section">
                             <a href="#"><img src="assets/linkedin1.png" alt="LinkedIn"/></a>
                             <p>Share on Twitter</p>
                      </div>
                      <div className="share_sub_section">
                              <a href="#"><img src="assets/twitter.png" alt="Twitter"/></a>
                              <p>Share on Linkedin</p>
                      </div>
            </div>
            <div className="job_apply_btn_container">
              <button className="job_apply_btn" onClick={()=>handleApplyNow(selectedJobIndex)}>Apply now</button>
            </div>
        </div>
    </div>
    </>
  )
}

import React, { useState, useEffect, useRef } from "react";
import "./it.css";
import paragraphImage1 from "../Assets/IT/Offshore_Production.jpg";
import paragraphImage2 from "../Assets/IT/BugFixes.jpg";
import paragraphImage3 from "../Assets/IT/Existing_Application.jpg";
import paragraphImage4 from "../Assets/IT/Continuous_Improvements.jpg";
import paragraphImage5 from "../Assets/IT/Performance.jpg";
import paragraphImage6 from "../Assets/IT/Mainframe_Application.jpg";

const points = [
  {
    title: "Offshore Production Support",
    description:
      "Our offshore production support ensures seamless operation and maintenance of your business applications around the clock. By leveraging a global team of experts, we provide timely resolutions and proactive monitoring to keep your applications running smoothly and efficiently.",
    image: paragraphImage1,
  },
  {
    title: "Bug Fixes and Enhancements",
    description:
      "We prioritize the swift identification and resolution of bugs within your applications, ensuring minimal disruption to your operations. Additionally, we implement enhancements to improve functionality, performance, and user experience, helping your applications evolve with your business needs.",
    image: paragraphImage2,
  },
  {
    title: "Existing Application & Customization Assessment",
    description:
      "Our comprehensive assessment evaluates your current applications to identify areas for improvement and customization. We analyze performance, security, and functionality to recommend and implement tailored solutions that align with your specific business requirements.",
    image: paragraphImage3,
  },
  {
    title: "Continuous Improvements",
    description:
      "We are committed to ongoing enhancements of your business applications through regular updates and optimizations. By staying abreast of technological advancements and industry best practices, we ensure that your applications remain cutting-edge and meet evolving user expectations.",
    image: paragraphImage4,
  },
  {
    title: "Performance & Stability Initiatives",
    description:
      "Our initiatives focus on boosting the performance and stability of your applications. We conduct thorough performance testing, fine-tune system configurations, and implement best practices to ensure reliable and efficient operation, even under high demand.",
    image: paragraphImage5,
  },
  {
    title: "Mainframe Application Support",
    description:
      "We offer specialized support for mainframe applications, including maintenance, troubleshooting, and updates. Our team of mainframe experts ensures the continued reliability and functionality of your legacy systems, integrating them seamlessly with modern technologies.",
    image: paragraphImage6,
  },
];

const ITBanner = ({handleContactClick}) => {
  const Banner_data={
      heading:"IT Operational Excellence",
  }
return (
  <div className="it_banner_container">
      <div className="it_bannerContent">
          <h2 className="it_banner_heading">{Banner_data.heading}</h2>
          <button className="it-banner-button" onClick={handleContactClick}>Contact Us</button>
      </div>
  </div>
)
}
const IT = ({handleContactClick}) => {
  const [activeIndex, setActiveIndex] = useState(0); // Initialize with 0 for default expansion
  const contentWrapperRef = useRef(null);
  const headingRef = useRef(null);
  const paragraphTextRef = useRef(null);
  const paragraphImageRef = useRef(null);

  const handleExpand = (index) => {
    // Prevent deactivation of the currently active index
    if (activeIndex !== index) {
      setActiveIndex(index);
    }
  };

  useEffect(() => {
    const elements = [
      headingRef.current,
      paragraphTextRef.current,
      paragraphImageRef.current,
      contentWrapperRef.current,
    ];

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("reveal");
            observer.unobserve(entry.target);
          }
        });
      },
      { threshold: 0.1 }
    );

    elements.forEach((element) => {
      if (element) observer.observe(element);
    });

    return () => {
      elements.forEach((element) => {
        if (element) observer.unobserve(element);
      });
    };
  }, []);

  const currentPoint = points[activeIndex] || {
    image: "",
    title: "Default Title",
  }; // Safeguard

  return (
    <div className="it-banner-container">
      <ITBanner handleContactClick={handleContactClick}/>
      <div className="it-paragraph">
        <h2 ref={headingRef} className="it-paragraph-heading">
          IT Operational Excellence
        </h2>
        <p ref={paragraphTextRef} className="it-paragraph-text">
          At Xploria, IT Operational Excellence is at the core of our commitment
          to delivering exceptional business application management. We focus on
          optimizing, improving, and sustaining your IT operations to ensure
          peak performance and reliability. Our approach involves rigorous
          monitoring, proactive issue resolution, and continuous enhancements to
          align with industry best practices. By leveraging cutting-edge
          technologies and advanced analytics, we drive efficiency and mitigate
          risks, ensuring your IT infrastructure supports your business goals
          seamlessly. Our dedicated team employs innovative strategies to
          streamline processes, reduce downtime, and enhance system stability.
          We also emphasize robust security measures and compliance to protect
          your data and maintain operational integrity. With Xploria's IT
          Operational Excellence, you can trust that your IT systems are not
          only functioning at their best but are also primed for future growth
          and innovation. Our holistic approach ensures that every aspect of
          your IT operations contributes to your overall business success.
        </p>
      </div>
      <div className="it-content">
        <h3 className="it-components-heading">
          Operational Excellence Process
        </h3>
        <div ref={contentWrapperRef} className="it-content-wrapper">
          <div className="it-image-container">
            <img
              key={activeIndex}
              src={currentPoint.image} // Safeguard
              alt={currentPoint.title}
              className="it-paragraph-image"
              ref={paragraphImageRef}
            />
          </div>
          <div className="it-text-content">
            {points.map((point, index) => (
              <div key={index} className="it-point">
                <button
                  onClick={() => handleExpand(index)}
                  className={`it-point-title ${
                    activeIndex === index ? "it-active" : ""
                  }`}
                  disabled={activeIndex === index} // Disable if it's active
                >
                  <span className="it-point-icon"></span>
                  {point.title}
                </button>
                {activeIndex === index && (
                  <div className="it-point-details">
                    <p className="it-point-description">{point.description}</p>
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default IT;

import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Routes, useNavigate } from "react-router-dom";
import ReactDOM from "react-dom/client";
import "./index.css";

// Importing components
import { IconsSlide, LandingPage } from "./components/HomePage";
import { Location } from "./components/Location";
import { Footer } from "./components/Footer";
import { Loading } from "./components/Loading";
import { Exp } from "./components/Exp";
import { Counter } from "./components/Counter";
import { Test } from "./components/Test";
import { EmpEng } from "./components/EmpEng";
import { ContactPage } from "./components/ContactPage";
import { Navbar } from "./components/Navbar";
import { CareersPage } from "./components/CareersPage";
import { ProposalPage } from "./components/ProposalPage";
import { JobDetails } from "./components/JobDetails";
import Technology from "./ExpertiseComponents/Technology/technology";
import Business from "./ExpertiseComponents/Business_Page/Business";
import PLM from "./ExpertiseComponents/PLM_Page/plm";
import AI from "./ExpertiseComponents/AI_page/ai";
import IT from "./ExpertiseComponents/IT_Page/it";
import Quality from "./ExpertiseComponents/Quality_Page/quality";
import { AboutusPage } from "./components/AboutusPage";
import { ApplyPage } from "./components/ApplyPage";
import JOB_DATA from './jobDetails.json';
import job_card_data from './JobCard.json'
import { PrivacyPolicy } from "./components/PrivacyPolicy";



const App = () => {
  const [loading, setLoading] = useState(true);
  const [showContact, setShowContact] = useState(false);
  const [showCareers, setShowCareers] = useState(false);
  const [showProposal, setShowProposal] = useState(false);
  const [moreDetail, setMoreDetail] = useState(false);
  const [showAbout, setShowAbout] = useState(false);
  const [dropClick, setDropClick] = useState(false);
  const [applyNow, setApplyNow] = useState(false);
  const [selectedJobIndex, setSelectedJobIndex] = useState(0);
  const [scrollToLocation, setScrollToLocation] = useState(false);


  // Nav Style
  const [menu, showMenu] = useState(false);
  const [headerColor, setHeaderColor] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    if (scrollToLocation) {
      window.scrollTo({ top: 2700, behavior: 'smooth' });
      setScrollToLocation(false);
    }
  }, [scrollToLocation]);

  useEffect(() => {
    if (!dropClick) {
      navigate('/');
    }
  }, [dropClick, navigate]);

  const DropPages = () => {
    setDropClick(true);
    setShowCareers(false);
    setShowContact(false);
    setShowProposal(false);
    setMoreDetail(false);
    setShowAbout(false);
    setApplyNow(false);
    showMenu(false)
    setHeaderColor(false);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const handleCareersClick = () => {
    setShowCareers(true);
    setShowContact(false);
    setShowProposal(false);
    setDropClick(false);
    setMoreDetail(false);
    setShowAbout(false);
    setApplyNow(false);
    showMenu(false)
    setHeaderColor(false);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const handleContactClick = () => {
    setShowContact(true);
    setShowCareers(false);
    setShowProposal(false);
    setDropClick(false);
    setMoreDetail(false);
    setShowAbout(false);
    setApplyNow(false);
    showMenu(false)
    setHeaderColor(false);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const handleBackbtn = () => {
    setShowCareers(true);
    setShowContact(false);
    setShowProposal(false);
    setDropClick(false);
    setMoreDetail(false);
    setShowAbout(false);
    setApplyNow(false);
    window.scrollTo({ top: 1800, behavior: 'smooth' });
  };

  const moreDetailHandle = (index) => {
    setMoreDetail(true);
    setShowContact(false);
    setShowCareers(false);
    setShowProposal(false);
    setShowAbout(false);
    setDropClick(false);
    setApplyNow(false);
    setSelectedJobIndex(index);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const handleApplyNow = (index) => {
    setApplyNow(true);
    setShowAbout(false);
    setMoreDetail(false);
    setShowContact(false);
    setShowCareers(false);
    setShowProposal(false);
    setDropClick(false);
    setSelectedJobIndex(index);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const handleAboutUs = () => {
    setShowAbout(true);
    setMoreDetail(false);
    setShowContact(false);
    setShowCareers(false);
    setShowProposal(false);
    setDropClick(false);
    setApplyNow(false);
    showMenu(false)
    setHeaderColor(false);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const handleLocation = (e) => {
    e.preventDefault();
    setShowContact(false);
    setShowAbout(false);
    setMoreDetail(false);
    setShowCareers(false);
    setShowProposal(false);
    setDropClick(false);
    setApplyNow(false);
    showMenu(false)
    setHeaderColor(false);
    setScrollToLocation(true);
  };

  const returnHome = () => {
    setShowContact(false);
    setShowCareers(false);
    setShowProposal(false);
    setDropClick(false);
    setMoreDetail(false);
    setShowAbout(false);
    setApplyNow(false);
    showMenu(false)
    setHeaderColor(false);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 800);
  }, []);

  return (
    <React.StrictMode>
      {loading ? (
        <Loading />
      ) : (
        <>
          <Navbar 
            DropPages={DropPages} 
            handleContactClick={handleContactClick} 
            handleCareersClick={handleCareersClick} 
            returnHome={returnHome} 
            handleAboutUs={handleAboutUs} 
            handleLocation={handleLocation} 
            menu={menu}
            showMenu={showMenu}
            headerColor={headerColor}
            setHeaderColor={setHeaderColor}
          />

          {dropClick ? (
            <Routes>
              <Route path="/technology" element={<Technology handleContactClick={handleContactClick}/>} />
              <Route path="/business" element={<Business handleContactClick={handleContactClick}/>} />
              <Route path="/plm" element={<PLM handleContactClick={handleContactClick}/>} />
              <Route path="/ai" element={<AI handleContactClick={handleContactClick}/>} />
              <Route path="/it" element={<IT handleContactClick={handleContactClick}/>} />
              <Route path="/quality" element={<Quality handleContactClick={handleContactClick}/>} />
              <Route path="/privacy-policy" element={<PrivacyPolicy/>} />
            </Routes>
          ) : showCareers ? (
            <CareersPage moreDetailHandle={moreDetailHandle} handleApplyNow={handleApplyNow} job_card_data={job_card_data}/>
          ) : showAbout ? (
            <AboutusPage handleContactClick={handleContactClick}/>
          ) : showContact ? (
            <ContactPage />
          ) : showProposal ? (
            <ProposalPage />
          ) : moreDetail ? (
            <JobDetails 
              selectedJobIndex={selectedJobIndex} 
              handleBackbtn={handleBackbtn} 
              handleApplyNow={handleApplyNow} 
              job_Datas={JOB_DATA} 
            />
          ) : applyNow ? (
            <ApplyPage job_Datas={JOB_DATA} selectedJobIndex={selectedJobIndex} />
          ) : (
            <>
              <LandingPage 
                setShowContact={setShowContact} 
                setShowCareers={setShowCareers} 
                setShowProposal={setShowProposal} 
              />
              <Exp DropPages={DropPages} />
              <Counter />
              <IconsSlide />
              <Test />
               {/* <EmpEng />  */}
              <Location />
              
            </>
          )}
          <Footer 
            DropPages={DropPages} 
            handleContactClick={handleContactClick} 
            handleCareersClick={handleCareersClick} 
            returnHome={returnHome} 
            handleAboutUs={handleAboutUs} 
            handleLocation={handleLocation} 
          />
        </>
      )}
    </React.StrictMode>
  );
};

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Router>
    <App />
  </Router>
);

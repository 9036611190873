import React, { useState, useEffect, useRef } from "react";
import "./ai.css";
import paragraphImage1 from "../Assets/AI/Data_Collection.jpg";
import paragraphImage2 from "../Assets/AI/Data_Verification.jpg";
import paragraphImage3 from "../Assets/AI/Image_Recognition.jpg";
import paragraphImage4 from "../Assets/AI/Predictive_Analytics.jpg";

const points = [
  {
    title: "Data Collection",
    description:
      "The process of gathering and measuring information on targeted variables to provide a comprehensive dataset for analysis. This includes sourcing data from various channels, ensuring it's relevant and up-to-date for subsequent processing and analysis.",
    image: paragraphImage1,
  },
  {
    title: "Data Verification",
    description:
      "Involves checking and validating the accuracy, quality, and integrity of data. This step ensures that the data used for analysis is reliable and free from errors, which is crucial for making accurate predictions and informed decisions.",
    image: paragraphImage2,
  },
  {
    title: "Image Recognition",
    description:
      "The use of statistical techniques and machine learning algorithms to analyze historical data and predict future outcomes. This helps businesses forecast trends, identify potential risks, and make data-driven decisions to enhance their strategies and operations.",
    image: paragraphImage3,
  },
  {
    title: "Predictive Analytics",
    description:
      "The use of statistical techniques and machine learning algorithms to analyze historical data and predict future outcomes. This helps businesses forecast trends, identify potential risks, and make data-driven decisions to enhance their strategies and operations.",
    image: paragraphImage4,
  },
];


const AIBanner = ({handleContactClick}) => {
  const Banner_data={
      heading:"AI/ML & Data Analytics",
  }
return (
  <div className="ai_banner_container">
      <div className="ai_bannerContent">
          <h2 className="ai_banner_heading">{Banner_data.heading}</h2>
          <button className="ai-banner-button" onClick={handleContactClick}>Contact Us</button>
      </div>
  </div>
)
}

const AI = ({handleContactClick}) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const contentWrapperRef = useRef(null);
  const headingRef = useRef(null);
  const paragraphTextRef = useRef(null);
  const paragraphImageRef = useRef(null);

  const handleExpand = (index) => {
    if (activeIndex !== index) {
      setActiveIndex(index);
    }
  };

  useEffect(() => {
    const elements = [
      headingRef.current,
      paragraphTextRef.current,
      paragraphImageRef.current,
      contentWrapperRef.current,
    ];

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("reveal");
            observer.unobserve(entry.target);
          }
        });
      },
      { threshold: 0.1 }
    );

    elements.forEach((element) => {
      if (element) observer.observe(element);
    });

    return () => {
      elements.forEach((element) => {
        if (element) observer.unobserve(element);
      });
    };
  }, []);

  const currentPoint = points[activeIndex] || {
    image: "",
    title: "Default Title",
  };

  return (
    <div className="ai-banner-container">
      <AIBanner handleContactClick={handleContactClick}/>
      <div className="ai-paragraph">
        <h2 ref={headingRef} className="ai-paragraph-heading">
          AI/ML & Data Analytics
        </h2>
        <p ref={paragraphTextRef} className="ai-paragraph-text">
          AI/ML and Data Analytics are transformative tools that empower businesses to derive actionable insights from vast amounts of data. By leveraging artificial intelligence and machine learning, organizations can automate data analysis, uncover hidden patterns, and gain a deeper understanding of their operations. This technology enables predictive analytics, allowing companies to forecast trends and make proactive decisions. Implementing a scalable GPU-powered AI/ML platform enhances computational efficiency, enabling faster processing of complex algorithms. With advanced data collection and verification, the accuracy and reliability of insights are ensured. Image recognition capabilities further expand the potential applications, from quality control to customer experience improvements. Overall, AI/ML and Data Analytics drive innovation, optimize performance, and provide a competitive edge. Businesses that harness these technologies can improve their strategic planning, operational efficiency, and market responsiveness. By continuously learning and adapting, AI/ML systems help organizations stay ahead in an ever-evolving landscape.
        </p>
      </div>
      <div className="ai-content">
        <h3 className="ai-components-heading">AI Process</h3>
        <div ref={contentWrapperRef} className="ai-content-wrapper">
          <div className="ai-image-container">
            <img
              key={activeIndex}
              src={currentPoint.image}
              alt={currentPoint.title}
              className="ai-paragraph-image"
              ref={paragraphImageRef}
            />
          </div>
          <div className="ai-text-content">
            {points.map((point, index) => (
              <div key={index} className="ai-point">
                <button
                  onClick={() => handleExpand(index)}
                  className={`ai-point-title ${activeIndex === index ? "ai-active" : ""}`}
                  disabled={activeIndex === index}
                >
                  <span className="ai-point-icon"></span>
                  {point.title}
                </button>
                {activeIndex === index && (
                  <div className="ai-point-details">
                    <p className="ai-point-description">{point.description}</p>
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AI;

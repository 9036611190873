
import "../css/ContactPage.css"




export const ContactPage = () => {
  return (
    <div>
        <ContactBanner/>
        <ContactBox/>
        <MapSection/>
    </div>
  )
}



const ContactBanner = () => {
    const Banner_data={
        heading:"Let’s Keep In Touch",
        description:"For general queries, sales and partnerships please drop a email anytime and we will reach you",
    }
  return (
    <div className="contact_banner_container">
        <div className="contact_bannerContent">
            <h2 className="contact_banner_heading" data-aos="fade-down" data-aos-duration="1000" >{Banner_data.heading}</h2>
            <p className="contact_banner_desc" data-aos="fade-down" data-aos-duration="1000" >{Banner_data.description}</p>
        </div>
    </div>
  )
}


const ContactBox = () => {
  return (
    <div className="contact">
        <div className="contact_container">
            <div className="contact_left">
                            <div className="contact_us">
                                <i class="fa-solid fa-circle right_icons glow_icon"></i><h2 className="contact_us_tittle">Contact Us</h2>
                            </div>
                   <div className="input_fields">
                            <div className="name_input">
                                <div className="first_name_input_box  contact_input_box">
                                    <input type="text"  placeholder=" "/>
                                     <label htmlFor="">First Name</label>
                                    <i class="fa-solid fa-user name_icons"></i>  
                                </div>
                                <div className="last_name_input_box contact_input_box">
                                    <input type="text"  placeholder=" "/>
                                    <label htmlFor="">Last Name</label>
                                    <i class="fa-solid fa-user name_icons"></i>
                                </div>
                            </div>
                            <div className="gmail_input contact_input_box">
                                    <input type="text"  placeholder=" "/>
                                    <label htmlFor="">Gmail</label>
                                    <i class="ri-mail-fill input_icons"></i>
                            </div>
                            <div className="phone_input contact_input_box">
                                    <input type="text"  placeholder=" "/>
                                    <label htmlFor="">Phone</label>
                                    <i class="ri-cellphone-fill input_icons"></i>
                            </div>
                            <div className="subject_input contact_input_box">
                                    <input type="text"  placeholder=" "/>
                                    <label htmlFor="">Subject</label>
                                    <i class="ri-file-text-fill input_icons"></i>
                            </div>
                            <div className="subject_input contact_input_box" >
                                    <textarea  cols="30" rows="5" placeholder=" " ></textarea>
                                    <label htmlFor="" id="contact_txtarea_lbl">Your Message</label>
                            </div>
                   </div>
                   <div className="btn_section">
                        <button className="attachment_btn"><i class="ri-link attachment_icon"></i>Add Attachment</button>
                        <button className="send_btn">Send message</button>
                   </div>
            </div>

            <div className="contact_right">
                        <div className="reach_us">
                            <i class="fa-solid fa-circle right_icons glow_icon"></i><h2 className="reach_us_tittle"> Reach Us</h2>
                        </div>
                        <div className="location_box">
                            <i class="ri-map-pin-line right_icons"></i>
                            <p>#104, T1, Third Floor,
                                13th Main Road, Jakkasandra,
                                Sector 7, HSR Layout 5th Sector,
                                Bengaluru – 560034</p>
                        </div>
                        <div className="phone_box">
                             <i class="ri-phone-line right_icons"></i>
                             <p>+91990903-3806</p>
                        </div>
                        <div className="email_box">
                            <i class="ri-mail-send-line right_icons"></i>
                            <p>adminxploria@gmail.com</p>
                        </div> 
                        <div className="icons_box">
                            <i class="ri-facebook-fill social___icons"></i>
                            <i class="ri-twitter-fill social___icons"></i>
                            <i class="ri-google-fill social___icons"></i>
                        </div>    
            </div>
        </div>
    </div>
  )
}


const MapSection = () => {
    return (
        <iframe className="contact_map_section"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3888.828820558956!2d77.63524947358803!3d12.918721216038431!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae150173a9df0f%3A0xd30009b0e83ae1ea!2sXPLORIA!5e0!3m2!1sen!2sin!4v1722256735047!5m2!1sen!2sin"
        
          style={{ border: 0 }}
          allowFullScreen=""
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
        ></iframe>
    );
  };
  

import "../css/Footer.css"
import { Brand } from "./HomePage"
import { Link } from 'react-router-dom';
import { useNavigate } from "react-router-dom";


export const Footer = ({DropPages,handleContactClick,handleCareersClick,returnHome,handleAboutUs,handleLocation}) => {
    // const navigate = useNavigate();

    const currentYear = new Date().getFullYear();
  return (
   
    <footer>
        <div class="footer-container">
            <div class="footer-section">
                    <div className='footer-brand'>
                            <a href=""><img src="/assets/xp_logo.png" alt="" /></a>
                            <div className="footer-brandname">XPLORIA</div>
                    </div> 
            </div>
            <div class="footer-section">
                <h4>What We Do ?</h4>
                <div className="box">
                        <Link to="/plm" onClick={DropPages}>PLM & Engineering Services</Link>
                        <Link to="/business" onClick={DropPages}>Business Applications</Link>
                        <Link to="/technology" onClick={DropPages}>Technology Consulting</Link>
                        <Link to="/ai" onClick={DropPages}>AI/ML & Data Analytics</Link>
                        <Link to="/it" onClick={DropPages}>IT Operational Excellence</Link>
                        <Link to="/quality" onClick={DropPages}>Quality Engineering</Link>
                </div>
            </div>
            <div class="footer-section">
                <h4>Quick Links</h4>
                <div className="box">
                    <a  onClick={e => returnHome(e, 'xp_exp_page')}>Our Expertise</a>
                    <a onClick={handleAboutUs}>About Us</a>
                    <a onClick={handleCareersClick}>Careers</a>
                    <a href="#location" onClick={handleLocation}>Locations</a>
                    <a onClick={handleContactClick}>Contact Us</a>
                </div>
            </div>
            <div class="footer-section">
                <h4>Follow Us</h4>
                <div class="social-icons">
                    <a href="#"><img src="assets/facebook.png" alt="Facebook"/></a>
                    <a href="#"><img src="assets/linkedin1.png" alt="Twitter"/></a>
                    <a href="#"><img src="assets/twitter.png" alt="LinkedIn"/></a>
                </div>
            </div>
        </div>

        <div className="footer-bottom-container">
            <p>Xploria © Copyright {currentYear} | All rights reserved.</p>
            <Link to="/privacy-policy" onClick={DropPages}>Privacy Policy</Link>
        </div>
    </footer>
   
  )
}

import React, { useState, useEffect, useRef } from "react";
import "./plm.css";
import paragraphImage1 from "../Assets/PLM/BOM.jpg";
import paragraphImage2 from "../Assets/PLM/CollaborativeProduct.png";
import paragraphImage3 from "../Assets/PLM/ChangeManagement.jpg";
import paragraphImage4 from "../Assets/PLM/PartsClassification.jpg";
import paragraphImage5 from "../Assets/PLM/PDM.jpg";

const points = [
  {
    title: "Bill of Materials (BOM) Management",
    description:
      "BOM Management ensures accurate and up-to-date information about the components and assemblies required to build a product. It helps in organizing and maintaining a complete list of materials, their quantities, and specifications, facilitating efficient production and inventory management.",
    image: paragraphImage1,
  },
  {
    title: "Collaborative Product Development",
    description:
      "This component enables teams across different departments and geographies to work together seamlessly on product design and development. It fosters innovation and accelerates time-to-market by improving communication, sharing of ideas, and coordination among stakeholders.",
    image: paragraphImage2,
  },
  {
    title: "Engineering Change Management",
    description:
      "Engineering Change Management helps in managing and tracking changes to product designs throughout the product lifecycle. It ensures that all modifications are properly documented, approved, and implemented, reducing errors and maintaining product integrity.",
    image: paragraphImage3,
  },
  {
    title: "Parts Classification",
    description:
      "Parts Classification involves organizing and categorizing parts and components to simplify searching, reusing, and managing them. It helps in standardizing parts, reducing duplication, and optimizing inventory.",
    image: paragraphImage4,
  },
  {
    title: "Product Data Management (PDM)",
    description:
      "PDM is the practice of managing product-related data throughout the lifecycle of the product. It includes the storage, retrieval, and sharing of information such as CAD models, documents, and specifications, ensuring data consistency and accessibility.",
    image: paragraphImage5,
  },
];

const Plm_Banner = ({handleContactClick}) => {
  const Banner_data={
      heading:"Plm & Engineering Services",
  }
return (
  <div className="tech_banner_container">
      <div className="tech_bannerContent">
          <h2 className="tech_banner_heading">{Banner_data.heading}</h2>
          <button className="tech-banner-button" onClick={handleContactClick}>Contact Us</button>
      </div>
  </div>
)
}
const PLM = ({handleContactClick}) => {
  const [activeIndex, setActiveIndex] = useState(0); // Initialize with 0 for default expansion
  const contentWrapperRef = useRef(null);
  const headingRef = useRef(null);
  const paragraphTextRef = useRef(null);
  const paragraphImageRef = useRef(null);

  const handleExpand = (index) => {
    // Prevent deactivation of the currently active index
    if (activeIndex !== index) {
      setActiveIndex(index);
    }
  };

  useEffect(() => {
    const elements = [
      headingRef.current,
      paragraphTextRef.current,
      paragraphImageRef.current,
      contentWrapperRef.current,
    ];

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("reveal");
            observer.unobserve(entry.target);
          }
        });
      },
      { threshold: 0.1 }
    );

    elements.forEach((element) => {
      if (element) observer.observe(element);
    });

    return () => {
      elements.forEach((element) => {
        if (element) observer.unobserve(element);
      });
    };
  }, []);

  const currentPoint = points[activeIndex] || {
    image: "",
    title: "Default Title",
  }; // Safeguard

  return (
    <div className="plm-banner-container">
      <Plm_Banner handleContactClick={handleContactClick}/>
      <div className="plm-paragraph">
        <h2 ref={headingRef} className="plm-paragraph-heading">
          Product Lifecycle Management
        </h2>
        <p ref={paragraphTextRef} className="plm-paragraph-text">
          Xploria's PLM & Engineering Services are designed to enable seamless
          digital integration throughout the product lifecycle. Our
          comprehensive approach includes PLM implementations, integrations, and
          application ecosystem optimization, ensuring that every phase of your
          product's journey is interconnected and efficient. Product Lifecycle
          Management (PLM) refers to the strategic approach of managing a
          product from its initial concept through its development,
          manufacturing, and eventual retirement. At Xploria, our PLM services
          focus on delivering tailored solutions that drive innovation,
          streamline processes, and enhance product quality. We offer expertise
          in Consulting & Value Road Map to guide strategic PLM planning,
          Solution Implementation to deploy robust systems, Application
          Management for ongoing support, Upgrade & Migration for evolving
          technology needs, Infrastructure Services to maintain a solid
          technological foundation, and Testing Services to ensure system
          reliability. Our PLM solutions bring significant benefits, including
          improved collaboration, reduced time-to-market, and enhanced product
          quality, while also supporting sustainable engineering by facilitating
          eco-friendly practices.
        </p>
      </div>
      <div className="plm-content">
        <h3 className="plm-components-heading">Components of PLM</h3>
        <div ref={contentWrapperRef} className="plm-content-wrapper">
          <div className="plm-image-container">
            <img
              key={activeIndex}
              src={currentPoint.image} // Safeguard
              alt={currentPoint.title}
              className="plm-paragraph-image"
              ref={paragraphImageRef}
            />
          </div>
          <div className="plm-text-content">
            {points.map((point, index) => (
              <div key={index} className="plm-point">
                <button
                  onClick={() => handleExpand(index)}
                  className={`plm-point-title ${
                    activeIndex === index ? "plm-active" : ""
                  }`}
                  disabled={activeIndex === index} // Disable if it's active
                >
                  <span className="plm-point-icon"></span>
                  {point.title}
                </button>
                {activeIndex === index && (
                  <div className="plm-point-details">
                    <p className="plm-point-description">{point.description}</p>
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PLM;

import { useEffect, useState } from "react";
import "../css/CareersPage.css";
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

AOS.init();

export const CareersPage = ({moreDetailHandle,handleApplyNow,job_card_data}) => {
  return (
    <div>
        <Career_Banner/>
        <WhyXploria/>
        <ContentSection/>
        <NumberList/>
        <JobSection moreDetailHandle={moreDetailHandle} handleApplyNow={handleApplyNow} job_card_data={job_card_data}/>
        <Client_Testimonials/> 
    </div>
  )
}


const Career_Banner = () => {
    const Banner_data={
        heading:"We're Hiring!",
        description:"We recruit individuals with the potential to contribute their skills, diversity, and talents to work cultural shaped my mutual respect ",
    }
  return (
    <div className="banner_container">
        <div className="bannerContent">
            <h2 className="banner_heading" data-aos="fade-down" data-aos-duration="1000" >{Banner_data.heading}</h2>
            <p className="banner_desc" data-aos="fade-down" data-aos-duration="1000" >{Banner_data.description}</p>
        </div>
    </div>
  )
}


const WhyXploria = () => {
    const Datas={
        desc:"We are a digitally native company that helps organizations reinvent themselves to create a way forward and unleash their potential. We are the place where innovation, design and engineering meet scale."
    }
    const boxDatas =[
        {img:"assets/young.jpg",title:"Young and Chilled Out"},
        {img:"assets/growth.png",title:"Ample Growth Opportunities"},
        {img:"assets/hand.png",title:"Be Proud of your work"},
        {img:"assets/yourself.png",title:"Make the best version of yourself"},
    ]
  return (
    <div className="xploria_service" >
        <div className="xploria_service_top" data-aos="fade-up" data-aos-duration="1000">
                 <h2 className="service_head">Why <span>Xploria?</span></h2>
                 <p className="service_desc">{Datas.desc}</p>
        </div>
        <div className="xploria_service_box_container" data-aos="fade-up" data-aos-duration="500">

                    {boxDatas.map((data)=>(
                                <div className="service_box" >
                                <img src={data.img} alt="" />
                                <p>{data.title}</p>
                            </div> 
                    ))}               
        </div>   
    </div>
  )
}


const ContentSection = () => {
  return (
    <div className="content_container">
            <div className="subcontent_box" >
                    <div className="content__box" data-aos="fade-up" data-aos-duration="1000">
                        <h2>Culture and Core Values</h2>
                        <p>We are obsessed about our customers and are guided only by user love. At Xploria we are transparent about the big picture and how each of us contributes to it. Here we like being resourceful and prioritize impact over hours. When you join, you'll witness how we are fiercely conscious about quality and work towards creating a legacy.</p>
                        {/* <button>Learn More</button> */}
                    </div>
                    <div className="content_img_box" data-aos="fade-up" data-aos-duration="1000" >
                        <img className="content_section_img" src="assets/discussion.jpg" alt="" />
                    </div>
             </div>


            <div className="subcontent_box">
            <div className="content_img_box" data-aos="fade-up" data-aos-duration="1000" >
                        <img className="content_section_img" src="assets/hand.jpg" alt="" />
                    </div>
                    <div className="content__box" data-aos="fade-up" data-aos-duration="1000" >
                            <h2>Why work with us</h2>
                            <p>Xploria is for you, if you'd like to build a truly disruptive product that solves a real problem for remote hybrid companies and teams. You'll get to work with world-beaters and learn from industry leaders. We love making time for deep work, hate micro-management and encourage bold honest conversations across teams.</p>
                            {/* <button>Learn More</button>         */}
                    </div>
            </div>
    </div>
  )
}

const NumberList = () => {

  const numdatas=[
    {title:"Employees Worldwide",count:"100+",img:"assets/employee.png"},
    {title:"Years Of Work",count:"12",img:"assets/yearsofwork.jpg"},
    {title:"Offices around the world",count:"5",img:"assets/world.png"},
    {title:"Fascinating Projects",count:"1000+",img:"assets/projects.jpg"}
  ]
  return (
    <div className="Number_list_container" >
      {numdatas.map((data)=>(
         <div className="num_box" data-aos="fade-up" data-aos-duration="1000">
          <img src={data.img} alt="" />
          <div className="num_detail">
              <h1 className="num_box_count">{data.count}</h1>
              <p className="num_box_title">{data.title}</p>
          </div>
     </div>
      ))}
    </div>
  )
}

const JobSection = ({moreDetailHandle,handleApplyNow,job_card_data}) => {

const [searchJob,setSearchJob] = useState('');
const [filteredJobs, setFilteredJobs] = useState(job_card_data);

useEffect(()=>{
  const results = job_card_data.filter(job=>
    job.role.toLowerCase().includes(searchJob.toLowerCase())
  );
      setFilteredJobs(results);
},[searchJob])



  return (
    <div className="jobsection_container">
            <h2 className="jobsection_heading" data-aos="fade-up" data-aos-duration="1000">Job Listing</h2>
                <div className="search_box" data-aos="fade-up" data-aos-duration="1000">
                    <input className="job_search" type="text" value={searchJob} onChange={(e)=>setSearchJob(e.target.value)}/>
                    <i class="ri-search-line job_search_icon"></i>
                </div>
            <div className="jobsection_boxes" data-aos="fade-up" data-aos-duration="1000">
            {filteredJobs.length > 0 ? (
          filteredJobs.map((data,index) => (


            <div className="jobsection_card" key={data.id}>
              <div className="jobsection_card_left">
                  <div className="job__img_box">
                    <img className="job__img" src={data.image} alt={data.role} />
                  </div>
                  <div className="job_details">
                    <h3 className="job_name">{data.role}</h3>
                    <div className="job_other_details">
                      <p className="job_location"><i className="ri-map-pin-fill"></i>{data.location}</p>
                    </div>
                  </div>
              </div>
              <div className="jobsection_card_right">
                    <p className="job_date"><i className="ri-calendar-2-line"></i>Posted On:<span>{data.date}</span></p>
                    <div className="btn_set">
                      <button className="moredetails_btn" onClick={() => moreDetailHandle(index)}>More Details</button>
                      <button className="apply_btn" onClick={()=>handleApplyNow(index)}>Apply Now</button>
                    </div>
              </div>
            </div>
          ))
        ) : (
          <p className="no_matches">No matches found</p>
        )}
                 
          </div>
    </div>
  )
}

const Client_Testimonials = () => {
  const clientDatas = [
    {name: "Gohul Krishna", profession: "Profession", img: "assets/client1.jpg", desc: "Desc1: Lorem ipsum dolor sit amet, consectetur adipisicing elit. Cum rem saepe, obcaecati ratione non voluptates corporis itaque voluptatib"},
    {name: "Hari", profession: "Profession", img: "assets/client2.jpg", desc: "Desc2: Lorem ipsum dolor sit amet, consectetur adipisicing elit. Cum rem saepe, obcaecati ratione non voluptates corporis itaque voluptatib"},
    {name: "Ram", profession: "Profession", img: "assets/client3.jpg", desc: "Desc3: Lorem ipsum dolor sit amet, consectetur adipisicing elit. Cum rem saepe, obcaecati ratione non voluptates corporis itaque voluptatib"},
    // {name: "Ram", profession: "Profession", img: "assets/client3.jpg", desc: "Desc3: Lorem ipsum dolor sit amet, consectetur adipisicing elit. Cum rem saepe, obcaecati ratione non voluptates corporis itaque voluptatib"},
  ];

 

 
  return (
    <div className="client_container">
      <h1 >Our Clients Says!!!</h1>
      <div className="client_box_container">


      <Carousel
      showThumbs={false}
      showStatus={false}
      infiniteLoop={true}
      autoPlay={true}
      interval={3000}
      showArrows={false}
      swipeable={false}
      emulateTouch={true}
      centerMode={true}
      centerSlidePercentage={20}
      showIndicators={true}
      transitionTime={700}
      >
          {clientDatas.map((data, index) => (
            <div className="client_test_box" key={index}>
              <i className="fa-solid fa-quote-left"></i>
              <p className="client_desc">{data.desc}</p>
              <div className="client_details">
                <div className="client_img_box">
                  <img className="client_img" src={data.img} alt="" />
                </div>
                <div className="client_sub_box">
                  <h3 className="client_name">{data.name}</h3>
                  <p className="client_profession">{data.profession}</p>
                </div>
              </div>
            </div>
          ))}
        
        </Carousel>

      </div>
    </div>
  );
};


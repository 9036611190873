import React, { useState, useEffect, useRef } from "react";
import "./Business.css";
import paragraphImage1 from "../Assets/Business/Product_Concept.jpg";
import paragraphImage2 from "../Assets/Business/Engineering_Simulation.jpg";
import paragraphImage3 from "../Assets/Business/Technical_Publications.jpg";
import paragraphImage4 from "../Assets/Business/Engineering_Change.jpg";
import paragraphImage5 from "../Assets/Business/Depth_of_Experience.jpg";

const points = [
  {
    title: "Product Concept & Design",
    description:
      "Our team excels in transforming innovative ideas into practical product concepts. We focus on user-centered design, ensuring that the end product is both functional and aesthetically pleasing. Our approach involves thorough research, prototyping, and iterative design to create products that meet market demands and exceed user expectations.",
    image: paragraphImage1,
  },
  {
    title: "Engineering Simulation",
    description:
      "We use advanced engineering simulation techniques to analyze and optimize product performance. By simulating real-world conditions, we can predict how products will behave under various scenarios, leading to improved designs, reduced costs, and faster time-to-market. Our simulations cover a wide range of applications, from structural analysis to fluid dynamics.",
    image: paragraphImage2,
  },
  {
    title: "Technical Publications",
    description:
      "Our technical publications team produces comprehensive documentation that supports product development and usage. This includes manuals, guides, and online help systems that are clear, accurate, and user-friendly. Effective technical documentation ensures that users can fully utilize products and that development teams can maintain and enhance them efficiently.",
    image: paragraphImage3,
  },
  {
    title: "Engineering Change Management Software Tools",
    description:
      "We develop sophisticated software tools that automate engineering change management processes. These tools streamline workflows, reduce manual errors, and ensure that all changes are tracked and implemented efficiently. Our solutions enhance collaboration, improve productivity, and ensure that engineering processes are compliant with industry standards.",
    image: paragraphImage4,
  },
  {
    title: "Industry-specific Solutions and Depth of Experience",
    description:
      "With extensive experience across various industries, we provide tailored solutions that address specific challenges and opportunities. Our in-depth knowledge of industry-specific requirements allows us to deliver customized platforms that align with our clients' strategic goals. Whether it's automotive, aerospace, healthcare, or any other sector, our expertise ensures that we deliver optimal solutions that drive success.",
    image: paragraphImage5,
  },
];

const BUS_Banner = ({handleContactClick}) => {
  const Banner_data={
      heading:"Business Applications",
  }
return (
  <div className="bus_banner_container">
      <div className="bus_bannerContent">
          <h2 className="bus_banner_heading">{Banner_data.heading}</h2>
          <button className="bus-banner-button" onClick={handleContactClick}>Contact Us</button>
      </div>
  </div>
)
}

const Business = ({handleContactClick}) => {
  const [activeIndex, setActiveIndex] = useState(0); // Initialize with 0 for default expansion
  const contentWrapperRef = useRef(null);
  const headingRef = useRef(null);
  const paragraphTextRef = useRef(null);
  const paragraphImageRef = useRef(null);

  const handleExpand = (index) => {
    // Prevent deactivation of the currently active index
    if (activeIndex !== index) {
      setActiveIndex(index);
    }
  };

  useEffect(() => {
    const elements = [
      headingRef.current,
      paragraphTextRef.current,
      paragraphImageRef.current,
      contentWrapperRef.current,
    ];

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("reveal");
            observer.unobserve(entry.target);
          }
        });
      },
      { threshold: 0.1 }
    );

    elements.forEach((element) => {
      if (element) observer.observe(element);
    });

    return () => {
      elements.forEach((element) => {
        if (element) observer.unobserve(element);
      });
    };
  }, []);

  const currentPoint = points[activeIndex] || {
    image: "",
    title: "Default Title",
  }; // Safeguard

  return (
    <div className="business-banner-container">
     
      <BUS_Banner handleContactClick={handleContactClick}/>
      <div className="business-paragraph">
        <h2 ref={headingRef} className="business-paragraph-heading">
          Business Applications
        </h2>
        <p ref={paragraphTextRef} className="business-paragraph-text">
          At Xploria, we excel in creating scalable, secure, and robust business
          applications by leveraging cutting-edge technologies and enhanced user
          experiences. Our expertise in product concept and design ensures that
          our solutions are both innovative and user-centric. We employ advanced
          engineering simulation techniques to optimize application performance
          and reliability. Our technical publications provide comprehensive
          documentation that supports users and development teams alike. We
          develop engineering change management software tools that automate
          processes, enhance productivity, and ensure compliance. Our
          industry-specific solutions draw from a wealth of experience, offering
          tailored applications that meet unique sector needs. From concept to
          deployment, Xploria's business applications drive efficiency,
          collaboration, and success.
        </p>
      </div>
      <div className="business-content">
        <h3 className="business-components-heading">
          Components of Business Application
        </h3>
        <div ref={contentWrapperRef} className="business-content-wrapper">
          <div className="business-image-container">
            <img
              key={activeIndex}
              src={currentPoint.image} // Safeguard
              alt={currentPoint.title}
              className="business-paragraph-image"
              ref={paragraphImageRef}
            />
          </div>
          <div className="business-text-content">
            {points.map((point, index) => (
              <div key={index} className="business-point">
                <button
                  onClick={() => handleExpand(index)}
                  className={`business-point-title ${
                    activeIndex === index ? "business-active" : ""
                  }`}
                  disabled={activeIndex === index} // Disable if it's active
                >
                  <span className="business-point-icon"></span>
                  {point.title}
                </button>
                {activeIndex === index && (
                  <div className="business-point-details">
                    <p className="business-point-description">
                      {point.description}
                    </p>
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Business;

import "../css/Counter.css"
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useRef, useState } from "react";
import CountUp from 'react-countup';
import ScrollTrigger from "react-scroll-trigger";

AOS.init();


export const Counter = () => {

   const[counterOn,setCounterOn]=useState(false);


   
    return (
      <>
      <div class="count-box">
        <ScrollTrigger onEnter={()=>setCounterOn(true)}>
            <div class="stats-container" data-aos="fade-up" data-aos-duration="1000">
                <div class="stat-item">
                    <div class="number">{counterOn && <CountUp end={9000} duration={2.5} />} <span class="unit">Million+</span></div>
                    <div class="description">Authentications</div>
                </div>
                <div class="stat-item">
                    <div class="number"> {counterOn && <CountUp end={1000} duration={2.5} />} <span class="unit">Million+</span></div>
                    <div class="description">E Authentications</div>
                </div>
                <div class="stat-item">
                    <div class="number">{counterOn &&<CountUp end={401} duration={2.5} />} <span class="unit">Million+</span></div>
                    <div class="description">Subscribers</div>
                </div>

                <div class="stat-item">
                    <div class="number">{counterOn &&<CountUp end={401} duration={2.5} />} <span class="unit">Million+</span></div>
                    <div class="description">Subscribers</div>
                </div>
            </div>
            </ScrollTrigger>
        </div>
      </>
    )
  }
  
import { useRef, useState,useEffect} from "react";
import { useNavigate } from "react-router-dom";
import { Link } from 'react-router-dom';
import "../css/Exp.css";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import AOS from 'aos';
import 'aos/dist/aos.css';
AOS.init();



export const Exp = ({DropPages}) => {
    const cardData = [
        {
            imgSrc: "/assets/plm1.png",
            title: "PLM & Engineering Services",
            description:"Enable the digital thread through PLM implementations, integrations, and application ecosystem optimization",
            details: [
                " Consulting & Value Road Map",
                "Solution Implementation",
                "Application Management",
                "Upgrade & Migration",
                " Infrastructure Services",
                " Testing Services",
            ],
            path:"plm"
            
        },
        {
            imgSrc: "/assets/Business-application.jpg",
            title: "Business Applications",
            description:"Build a scalable, secure, robust platform by leveraging the cutting edge technologies and enhanced UX",
            details: [
                "Product Concept & Design",
                "Engineering Simulation",
                "Technical Publications",
                "Engineering Change Management Software Tools",
                "Industry-specific solutions. Depth of experience"
            ],
            path:"business"
        },
        {
            imgSrc: "/assets/tech-consult.jpg",
            title: "Technology Consulting",
            description:"Design, harmonize and automate business processes with improved flow and velocity across the value stream",
            details: [
                "Core Business Operations",
                "Strategy & Analytics",
                "Digital transformation",
                "Enterprise Technology & Performance",
            ],
            path:"technology"
        },
        {
            imgSrc: "/assets/ai-ml.jpg",
            title: "AI/ML & Data Analytics",
            description:"Build a scalable GPU powered AI/ML platform to provide your business insights",
            details: [
                " Data Collection",
                " Data Verification",
                "Predictive Analytics",
                "Image Recognition",
                "Predictive Analytics"
            ],
            path:"ai"
        },
        {
            imgSrc: "/assets/operational-excellence.jpg",
            title: "IT Operational Excellence",
            description:"Optimize, improve and manage the Business applications 24×7",
            details: [
                "Offshore Production Support",
                "Bug Fixes and Enhancements",
                "Existing Application & Customization assessment",
                " Continuous Improvements",
                "Performance & Stability Initiatives",
                "Mainframe application support"
            ],
            path:"it"
        },
        {
            imgSrc: "/assets/quality-engineering.webp",
            title: "Quality Engineering",
            description:"Helps the organization with comprehensive QA Services & Analysis",
            details: [
                " Automation Testing Services",
                " Manual Testing Services",
                "API Testing Services",
                "Security Testing Services",
                "Performance Testing Services",
                "QA Analysis",
                "IoT Testing Services"
            ],
            path:"quality"
        }
    ];

    const [currentSlide, setCurrentSlide] = useState(0);
    const [showMore, setShowMore] = useState(false);

    const handleNext = () => {
        setCurrentSlide((prev) => (prev + 1) % cardData.length);
        setShowMore(false)
    };

    const handlePrev = () => {
        setCurrentSlide((prev) => (prev - 1 + cardData.length) % cardData.length);
        setShowMore(false)
    };


    return (
        <div className="exp_card_container" id="xp_exp_page">
            <div className="exp_headings" data-aos="fade-up" data-aos-duration="1000">
                {/* <h1>Our Expertise</h1> */}
                <p className="exp_title">Xploria provides a range of collaborative and integrated solutions through its portfolio of services to industry segments such as Manufacturing, Hi-Tech, Industrial equipment, Health care, Life Sciences & Medical Devices, Education, Banking & Finance with a wide range of multinational customers in the US and APAC regions. We combine functional expertise with deep industry domain knowledge to provide industry-specific outcomes for our customers.</p>
            </div>
            <Exp_Card cards={cardData } currentSlide={currentSlide} setCurrentSlide={setCurrentSlide} showMore={showMore} setShowMore={setShowMore} DropPages={DropPages}/>
            <div className="navigation">
                <button className="nav-button" onClick={handlePrev}><i className="ri-arrow-left-line"></i></button>
                <span>{currentSlide + 1}/{cardData.length}</span>
                <button className="nav-button" onClick={handleNext}><i className="ri-arrow-right-line"></i></button>
            </div>
        </div>
    );
};

export const Exp_Card = ({ cards,currentSlide,setCurrentSlide,showMore, setShowMore,DropPages }) => {


    const handleReadMore = () => {
        setShowMore(!showMore);
    };

    const [autoPlay,setAutoPlay]=useState(false);
  

    return (
        <div className="exp_card_container">
          
            {/* <div className={`exp_cards ${showMore ? "show-more" : ""}`}> */}
        <div className="exp_cards" data-aos="fade-up" data-aos-duration="1000">
            <Carousel 
                selectedItem={currentSlide}
                onChange={(index) => setCurrentSlide(index)}
                showThumbs={false}
                showStatus={false}
                infiniteLoop={true}
                autoPlay={true}
                interval={3000}
                showArrows={false}
                swipeable={false}
                emulateTouch={true}
                centerMode={true}
                centerSlidePercentage={64}
                showIndicators={false}
                transitionTime={700}
            >
         
                {cards.map((card, index) => (
                    <div key={index} className="exp_card">
                        <div className="exp_img">
                            <img src={card.imgSrc} alt={card.title} />
                        </div>
                        <div className="exp_content">
                            <h2 className={`exp_title ${showMore ?'exp_title_center':''}`}>{card.title}</h2>
                            <p className={`exp_para ${showMore ? 'exp_para_center':''}`}>{card.description}</p>
                            {showMore && (
                                <>
                                    {/* <h4 className="our_expertise">Our Expertise:</h4> */}
                                    <ul className={`all_list ${showMore?'all_list_show':''}`}>
                                        {card.details.map((detail, index) => (
                                            <li key={index} className="list">
                                                <i className="ri-arrow-drop-right-line"></i>{detail}
                                            </li>
                                        ))}
                                    </ul>
                                </>
                            )}
                           
                                <div className="read_more_section" onClick={handleReadMore}>
                                    <a className="read_more">{showMore ? "Show Less" : "Read More"}</a>
                                    <button className="read_more_btn">
                                        <i className="ri-arrow-right-s-line"></i>
                                    </button>
                                </div>
                                <div className="expand_more_section">
                                    <Link  to={`/${card.path}`} className="expand__more__btn" onClick={DropPages}>Expand </Link>
                                    <i className="ri-arrow-right-s-line"></i>
                                </div>
                            
                        </div>
                    </div>
                ))}
              </Carousel>

            </div>
          
        </div>
    );
};
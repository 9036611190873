import "../css/ApplyPage.css"

export const ApplyPage = ({job_Datas,selectedJobIndex}) => {
  return (
    <div style={{backgroundColor:"#F6F6F6"}}>
        <ApplyBanner/>
        <ApplyForm job_Datas={job_Datas} selectedJobIndex={selectedJobIndex}/>
    </div>
  )
}


const ApplyBanner = () => {
    const Banner_data={
        heading:"Ready to join our Team?",
        description:"Discover exciting opportunities and take the next step in your career with us at XPLORIA",
    }
  return (
    <div className="apply_banner_container">
        <div className="apply_bannerContent">
            <h2 className="apply_banner_heading" data-aos="fade-down" data-aos-duration="1000" >{Banner_data.heading}</h2>
            <p className="apply_banner_desc" data-aos="fade-down" data-aos-duration="1000" >{Banner_data.description}</p>
            
        </div>
    </div>
  )
}



const ApplyForm = ({job_Datas,selectedJobIndex}) => {

    const {job_name} =job_Datas[selectedJobIndex];
    return (
        <div className="Apply_form_container">
            <p className="apply_form_para" data-aos="fade-up" data-aos-duration="1000">We are looking for passionate, talented individuals to join our dynamic team and help us shape the future of technology. If you’re driven, innovative, and ready to make an impact, apply now and become a part of our journey!</p>
        <form action="" className="apply_form" data-aos="fade-up" data-aos-duration="1000">

             <p className="apply_role_section"> <i class="fa-solid fa-circle right_icons glow_icon apply_role_glow"></i>{job_name}</p>
            <div className="apply_form_input_grp">
                    <input className="name_grp" type="text" placeholder=" "/>
                    <label htmlFor="">Name</label>
            </div>   
            <div className="apply_form_input_grp">
                <input className="email_grp" type="text" placeholder=" "/>
                <label htmlFor="">Email Address</label>
            </div>
            <div className="apply_form_input_grp">
                <input className="number_grp" type="text" placeholder=" "/>
                <label htmlFor="">Phone Number</label>
            </div>
            <label htmlFor="images" className="drop-container" id="dropcontainer">
                <span className="drop-title">Drag your resume to this area</span>or
                <input type="file" id="images" accept="image/*" required />
            </label>

            <button className="submit_btn">Submit</button>
        </form>
    </div>
      )
    }
    
